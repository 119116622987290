import React, { Fragment, useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

import {
  Flex,
  GoogleMap,
  ResponsiveAppBar,
  LightTextField,
} from '@solar/solar-react';

import PropTypes from 'prop-types';
import * as R from 'ramda';
import Fuse from 'fuse.js';

import { Typography, InputAdornment } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  searchInput: {
    width: theme.increments.tablet * 6,
    marginRight: 8,
    marginLeft: 8,
  },
}));

const MapWithAppBar = ({ onClickMarker, markers, appBarProps, ...other }) => {
  const classes = useStyles();
  const fuse = useMemo(() => {
    const options = {
      keys: ['title'],
      shouldSort: true,
      threshold: 0.3,
    };

    return new Fuse(markers, options);
  }, []);
  const fuseSearchMemoized = R.memoizeWith(R.identity, search =>
    fuse.search(search),
  );

  const [state, setState] = useState({
    search: '',
  });
  const { search } = state;
  const { title, ...otherAppBarProps } = appBarProps;

  return (
    <Fragment>
      <ResponsiveAppBar
        title={
          <Flex alignItems="center" justifyContent="space-between" flexGrow={1}>
            <Typography
              variant="h5"
              color="inherit"
              noWrap
              className={classes.title}
            >
              {title}
            </Typography>
            <LightTextField
              fullWidth
              box
              placeholder="Zoeken"
              value={search}
              onChange={event => setState({ search: event.target.value })}
              className={classes.searchInput}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="inherit" />
                  </InputAdornment>
                ),
              }}
            />
          </Flex>
        }
        disableShadow
        {...otherAppBarProps}
      />

      <GoogleMap
        isMarkerShown
        markers={search === '' ? markers : fuseSearchMemoized(search)}
        onClickMarker={onClickMarker}
        {...other}
      />
    </Fragment>
  );
};

MapWithAppBar.propTypes = {
  className: PropTypes.string,
  markers: PropTypes.array,
  onClickMarker: PropTypes.func,
};

MapWithAppBar.defaultProps = {
  className: '',
};

export default MapWithAppBar;
