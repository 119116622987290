import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import SearchIcon from '@material-ui/icons/Search';
import { InputAdornment, List } from '@material-ui/core';

import {
  Flex,
  ScrollableContent,
  ResponsiveAppBar,
  LightTextField,
  ChatListItem,
  SplitView,
} from '@solar/solar-react';

import ChatDetails from './ChatDetails';

const useStyles = makeStyles(theme => ({
  root: {},
  conversation: {
    width: '100%',
  },
  appBar: {
    boxShadow: 'none',
    backgroundColor: theme.palette.background.appBar,
  },
  topToolbar: {
    height: theme.heights.toolbarTop,
    justifyContent: 'space-between',
  },
  tabAppBar: {
    height: theme.heights.toolbarBottom,
    justifyContent: 'flex-end',
    padding: '0 24px',
    zIndex: 'auto',
  },
  tabButton: {
    minWidth: 'initial',
  },
  border: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  searchBox: {
    marginRight: 24,
  },
}));

const ChatSplitView = ({ onClickInfo, user, conversations, componentMap }) => {
  const classes = useStyles();
  const [state, setState] = useState({
    activeConversation: null,
    sideOpen: false,
  });
  const { activeConversation, sideOpen } = state;

  const openChat = conversationId => {
    setState({ sideOpen: true, activeConversation: conversationId });
  };

  const handleCloseSide = () => {
    setState({ sideOpen: false });
  };
  const a = activeConversation || conversations[0]._id;

  const conv = conversations.find(c => c._id === a);

  return (
    <SplitView
      onClose={handleCloseSide}
      open={sideOpen}
      sideContent={
        <ChatDetails
          user={user}
          className={classes.conversation}
          conversation={conv}
          onClickInfo={onClickInfo}
          componentMap={componentMap}
        />
      }
    >
      <Flex fullColumn>
        <ResponsiveAppBar
          title={
            <LightTextField
              fullWidth
              placeholder="Zoek een gesprek"
              className={classes.searchBox}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="inherit" />
                  </InputAdornment>
                ),
              }}
            />
          }
          disableShadow
        />

        <ScrollableContent classes={{ root: classes.border }}>
          <List>
            <div>
              {conversations.map(conversation => {
                // find last message from other user
                const { _id, messages } = conversation;
                const otherMessages = messages.filter(
                  m => m.user._id !== user._id,
                );
                const lastMessage = otherMessages[otherMessages.length - 1];
                return (
                  <ChatListItem
                    active={a === _id}
                    key={_id}
                    username={lastMessage.user.username}
                    avatarUrl={lastMessage.user.avatarUrl}
                    lastMessage={lastMessage.text || 'nothing'}
                    timestamp={lastMessage.time}
                    onClick={() => openChat(_id)}
                  />
                );
              })}
            </div>
          </List>
        </ScrollableContent>
      </Flex>
    </SplitView>
  );
};

ChatSplitView.propTypes = {
  componentMap: PropTypes.object,
  conversation: PropTypes.object,
  onClickInfo: PropTypes.func,
  user: PropTypes.object,
};

export default ChatSplitView;
