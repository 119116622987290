import React from 'react';
import PropTypes from 'prop-types';
import Route from 'react-router-dom/Route';

import UserProvider from '../UserProvider';

const GenericRoute = ({
  accounts,
  component: Component,
  layout: Layout,
  children,
  componentProps,
  layoutProps,
}) => (
  <Route>
    {props => (
      <Layout {...layoutProps}>
        <UserProvider accounts={accounts}>
          {({ currentUser }) => {
            return Component ? (
              <Component {...componentProps} {...props} user={currentUser} />
            ) : (
              children({
                ...componentProps,
                ...props,
                user: currentUser,
              })
            );
          }}
        </UserProvider>
      </Layout>
    )}
  </Route>
);

GenericRoute.propTypes = {
  component: PropTypes.func,
  componentProps: PropTypes.object,
  layout: PropTypes.func,
  layoutProps: PropTypes.object,
  loading: PropTypes.bool,
  render: PropTypes.func,
};

GenericRoute.defaultProps = {
  layout: ({ children }) => children,
};

// prettier-ignore
export default GenericRoute;
