import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  active: {
    backgroundColor: theme.palette.divider,
  },
}));

const PersonListItem = ({
  active,
  avatarUrl,
  classes: eClasses,
  className,
  secondaryText,
  username,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <ListItem
      button
      className={classNames(classes.root, active && classes.active, className)}
      {...other}
    >
      <ListItemAvatar>
        <Avatar src={avatarUrl} />
      </ListItemAvatar>
      <ListItemText primary={username} secondary={secondaryText} />
    </ListItem>
  );
};

PersonListItem.propTypes = {
  active: PropTypes.bool,
  avatarUrl: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  secondaryText: PropTypes.node,
  username: PropTypes.string.isRequired,
};

PersonListItem.defaultProps = {
  active: false,
  className: '',
  secondaryText: '',
};

// prettier-ignore
export default PersonListItem;
