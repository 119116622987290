import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, AppBar } from '@material-ui/core';

import { Flex, ScrollableContent, Toolbar, Scrim } from '@solar/solar-react';

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: theme.palette.background.paper,
    position: 'relative',
  },
  topToolbarBack: {
    backgroundColor: theme.palette.primary[800],
    position: 'absolute',
    height: 64,
    zIndex: 0,
    width: '100%',
    padding: 0,
  },
  topToolbar: {
    backgroundColor: theme.palette.primary[800],
    justifyContent: 'space-between',
    position: 'absolute',
    height: 64,
    zIndex: theme.zIndex.appBar + 1,
    width: '100%',
    boxSizing: 'border-box',
    padding: `0px ${theme.spacing(1)}px 0px 0px`,
  },
  topToolbarWithCover: {
    backgroundColor: 'transparent',
  },
  scrollContainer: {},
  appBar: {
    minHeight: 271,
    position: 'relative',
    backgroundColor: theme.palette.primary[800],
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
  },
  scrim: {
    zIndex: -1,
  },
  headerContent: {
    paddingTop: 64,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  spacer: {
    height: 0,
    margin: 0,
    padding: 0,
  },
  headerWrapper: {
    padding: 24,
    paddingTop: 0,
    paddingBottom: 16,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignContent: 'flex-end',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '2.2em',
    lineHeight: '1.2em',
  },
  titleWithCover: {
    color: 'white',
    fontSize: '1.4em',
    lineHeight: '1.2em',
    marginBottom: 8,
  },
}));

const CoverView = ({
  children,
  action,
  header,
  title,
  cover,
  themeProviderComponent: ThemeProvider,
  TitleProps,
  ...other
}) => {
  const classes = useStyles();
  return (
    <Flex fullColumn classes={{ root: classes.root }} {...other}>
      <Toolbar
        size="default"
        className={classNames(
          classes.topToolbar,
          cover && classes.topToolbarWithCover,
        )}
      >
        <div className="spacer" />
        {action}
      </Toolbar>
      <Toolbar size="default" className={classes.topToolbarBack} />
      <ScrollableContent classes={{ root: classes.scrollContainer }}>
        <AppBar
          classes={{
            root: classNames(classes.appBar),
          }}
        >
          {cover && <Scrim cover={cover} className={classes.scrim} />}
          <div className={classes.headerContent}>
            <div className={classes.headerWrapper}>
              <div className={classes.spacer} />
              {header}
              <Typography
                color="white"
                rows="1"
                margin="normal"
                className={classNames(
                  classes.title,
                  cover && classes.titleWithCover,
                )}
                {...TitleProps}
              >
                {title}
              </Typography>
            </div>
          </div>
        </AppBar>
        {children}
      </ScrollableContent>
    </Flex>
  );
};

CoverView.propTypes = {
  action: PropTypes.node,
  children: PropTypes.node,
  cover: PropTypes.string,
  header: PropTypes.node,
  themeProviderComponent: PropTypes.node,
};

export default CoverView;
