export default {
  login:
    'enter your email and we send you a link so you can continue with your profile',
  mailSent:
    'We sent you an email. Please click the link in this email to continue with your profile.',
  'email address': 'email address',
  invite:
    'This app is invite only. You can leave your email and if you were already invited, you\'ll recieve a email to login to this app.',
  sendAgain: 'try again',
  sendLink: 'email a link',
  password: 'password',
  signIn: 'sign in',
  loginPage: {
    signupCTAText: 'Don’t have an account yet?',
    signupCTALink: 'Sign up',
    resetCTAText: 'Forgot your password?',
    resetCTALink: 'Reset password',
    autoCTAText: 'Shortcut login!',
    autoCTALink: 'Enter here',
  },
  signUp: 'signUp',
  signupPage: {
    loginCTAText: 'Already have an account?',
    loginCTALink: 'Log in',
  },
  resetPage: {
    loginCTAText: 'Suddenly remember your password?',
    loginCTALink: 'Log in',
  },
  resetSentPage: {
    heading: 'Reset password email sent',
    body:
      'Check your email for a link to reset your password. If it doesn\'t appear within a few minutes, check your spam folder',
  },
  ballerLogin: {
    CTALink: 'Back to peasant login',
  },
};
