import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    bottom: -56 / 2,
    left: 24,
    color: theme.palette.common.white,
    zIndex: theme.zIndex.appBar + 1,
  },
}));

const PresetFab = ({
  classes: eClasses,
  className: classNameProp,
  Icon,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  const className = classNames(classes.root, classNameProp);

  return (
    <Fab color="primary" className={className} {...other}>
      <Icon />
    </Fab>
  );
};

PresetFab.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  Icon: PropTypes.number,
};

PresetFab.defaultProps = {
  className: '',
  Icon: AddIcon,
};

export default PresetFab;
