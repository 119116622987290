import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';

import { AppBar } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    boxShadow: 'none',
    backgroundColor: theme.palette.background.appBar,
  },
}));

const PresetAppBar = ({ children, theme, className, ...other }) => {
  const classes = useStyles();
  return (
    <MuiThemeProvider theme={theme}>
      <AppBar className={classNames(classes.root, className)} {...other}>
        {children}
      </AppBar>
    </MuiThemeProvider>
  );
};

PresetAppBar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  theme: PropTypes.object,
};

PresetAppBar.defaultProps = {
  children: '',
  className: '',
};

export default PresetAppBar;
