import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import classNames from 'classnames';

import { ChatToolbar, ChatInput } from '@solar/solar-react';

import AddIcon from '@material-ui/icons/AddCircle';

import EmojiIcon from '@material-ui/icons/SentimentVerySatisfied';
import SendIcon from '@material-ui/icons/Send';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
}));

const ChatToolbarMobile = ({ classes: eClasses, className }) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <ChatToolbar className={classNames(classes.root, className)}>
      <IconButton>
        <AddIcon color="primary" />
      </IconButton>
      <ChatInput
        placeholder="Typ een bericht..."
        adornment={
          <IconButton>
            <EmojiIcon color="primary" />
          </IconButton>
        }
      />
      <IconButton>
        <SendIcon color="primary" />
      </IconButton>
    </ChatToolbar>
  );
};

ChatToolbarMobile.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

ChatToolbarMobile.defaultProps = {
  className: '',
};

// prettier-ignore
export default ChatToolbarMobile;
