import { useState, useEffect } from "react";

const useKeys = keys => {
  const [pressed, setPressed] = useState(false);
  const toLowerCase = k => k.toLowerCase();
  const match = key => keys.map(toLowerCase).includes(toLowerCase(key));
  const keyHandler = v => event => {
    const { key } = event;
    if (match(key)) {
      setPressed(v ? key : null);
      event.stopPropagation();
      event.preventDefault();
    }
  };

  const onUp = keyHandler(false);
  const onDown = keyHandler(true);

  // Bind and unbind events
  useEffect(() => {
    window.addEventListener("keydown", onDown);
    window.addEventListener("keyup", onUp);
    return () => {
      window.removeEventListener("keydown", onDown);
      window.removeEventListener("keyup", onUp);
    };
  }, []);

  return pressed;
};

export default useKeys;
