import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, IconButton, TextField } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 12,
    marginLeft: 24,
    marginRight: 24,
  },
  editField: {},
  editKey: {
    width: 64,
    marginRight: 8,
  },
}));

export const EditSet = ({ label, set, onChange, ...props }) => {
  const classes = useStyles(props);
  return (
    <Fragment>
      {label}
      <div className={classes.root}>
        {set.map(c => (
          <div style={{ display: 'flex' }}>
            <TextField
              classes={{ root: classes.editField }}
              id="outlined-dense-multiline"
              margin="dense"
              variant="outlined"
              fullWidth
              value={c}
              onChange={event => {
                onChange(set.map(s => (s === c ? event.target.value : s)));
              }}
            />
            <IconButton
              onClick={() => {
                onChange(set.filter(s => s !== c));
              }}
            >
              <RemoveIcon />
            </IconButton>
          </div>
        ))}
        <Button onClick={() => onChange([...set, ''])}>toevoegen</Button>
      </div>
    </Fragment>
  );
};

EditSet.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  set: PropTypes.array.isRequired,
};

export default EditSet;
