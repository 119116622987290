import store from 'store2';

const onChangeCallbacks = [];

export const registerCallback = (key, fn) => {
  if (typeof key === 'function') {
    fn = key;
    key = null;
  }
  onChangeCallbacks.push({ key, fn });
};

const sideEffects = (key, value, options) => {
  onChangeCallbacks.forEach(callback => {
    if (callback.key === key || !callback.key) {
      callback.fn({ key, value, options });
    }
  });
};

store._set = store.set;

store.set = (...other) => {
  sideEffects(...other);
  store._set(...other);
};

export default registerCallback;
