import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Tabs, Tab } from '@material-ui/core';

import CoverView from './CoverView';

const useStyles = makeStyles(theme => ({
  root: {},
}));

const CoverTabView = ({
  themeProviderComponent,
  children,
  action,
  header,
  title,
  cover,
  tabs,
  TitleProps,
  ...other
}) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(Object.keys(tabs)[0]);
  const Comp = tabs[activeTab].component;
  return (
    <CoverView
      themeProviderComponent={themeProviderComponent}
      action={action}
      header={header}
      title={title}
      cover={cover}
      TitleProps={TitleProps}
    >
      <Tabs
        {...other}
        value={activeTab}
        indicatorColor="secondary"
        onChange={(event, t) => setActiveTab(t)}
      >
        {Object.keys(tabs).map(
          t => tabs[t].label && <Tab label={tabs[t].label} value={t} />,
        )}
      </Tabs>
      {Comp}
    </CoverView>
  );
};

CoverTabView.propTypes = {
  action: PropTypes.node,
  children: PropTypes.node,
  cover: PropTypes.string,
  header: PropTypes.node,
  tabs: PropTypes.object,
  themeProviderComponent: PropTypes.node,
};

export default CoverTabView;
