import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { makeStyles } from '@material-ui/core/styles';

import { ElementWidth } from '@solar/solar-react';

import CalendarColumnView from './CalendarColumnView';
import CalendarListView from './CalendarListView';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    '&.small': {
      backgroundColor: 'red',
    },
    '&.large': {
      backgroundColor: 'green',
    },
  },
  toolbar: {
    // Only for calendar, because it has to map on the columns
    paddingRight: 0,
  },
}));

const Calendar = ({
  onClickNavButton,
  onClickSlotItem,
  allDates,
  timeSlots,
  listViewDaysToRender,
  componentMap,
  ...other
}) => {
  const classes = useStyles();
  const [state, setState] = useState({
    dayOffSet: 0,
  });
  const { dayOffSet } = state;

  const changeOffset = value => () => {
    setState(({ dayOffSet }) => ({
      dayOffSet: dayOffSet + value,
    }));
  };

  return (
    <ElementWidth
      upMap={{
        960: { className: 'wide', days: 5 },
        700: { className: 'big', days: 4 },
        500: { className: 'big', days: 3 },
        0: { className: 'small', days: 2 },
      }}
      style={{ height: '100%' }}
    >
      {({ days }) => {
        if (days > 2) {
          const selectedDates = allDates.slice(
            dayOffSet,
            dayOffSet + (days || 1),
          );
          return (
            <CalendarColumnView
              onClickNavButton={onClickNavButton}
              selectedDates={selectedDates}
              days={days}
              timeSlots={timeSlots}
              changeOffset={changeOffset}
              dayOffSet={dayOffSet}
              componentMap={componentMap}
              onClickSlotItem={onClickSlotItem}
            />
          );
        }
        return (
          <CalendarListView
            selectedDates={allDates.slice(
              dayOffSet,
              dayOffSet + listViewDaysToRender,
            )}
            days={listViewDaysToRender}
            timeSlots={timeSlots}
            changeOffset={changeOffset}
            dayOffSet={dayOffSet}
            onClickSlotItem={onClickSlotItem}
          />
        );
      }}
    </ElementWidth>
  );
};

Calendar.propTypes = {
  allDates: PropTypes.array.isRequired,
  componentMap: PropTypes.object,
  listViewDaysToRender: PropTypes.number,
  onClickNavButton: PropTypes.func,
  onClickSlotItem: PropTypes.func.isRequired,
  timeSlots: PropTypes.object.isRequired,
};

Calendar.defaultProps = {
  listViewDaysToRender: 15,
};

/* prettier-ignore */
export default Calendar;
