import React from 'react';
import PropTypes from 'prop-types';
import Route from 'react-router-dom/Route';
import Redirect from 'react-router-dom/Redirect';

import UserProvider from '../UserProvider';

const allowedRoles = [];

const UnauthenticatedRoute = ({
  accounts,
  component: Component,
  layout: Layout,
  children,
  roles,
  componentProps,
  layoutProps,
}) => (
  <Route>
    {props => (
      <Layout {...layoutProps}>
        <UserProvider accounts={accounts}>
          {({ currentUser }) => {
            if (currentUser) {
              return <Redirect to={{ pathname: '/' }} />;
            }

            const rolesToCheck = roles || allowedRoles;
            if (
              rolesToCheck &&
              rolesToCheck.length &&
              rolesToCheck.length > 0
            ) {
              const currentRole = currentUser.role ? currentUser.role : '';
              // with role mgmt
              if (rolesToCheck.includes(currentRole)) {
                return Component ? (
                  <Component
                    {...componentProps}
                    {...props}
                    user={currentUser}
                    currentRole={currentRole}
                  />
                ) : (
                  children({
                    ...componentProps,
                    ...props,
                    user: currentUser,
                    currentRole,
                  })
                );
              }
              return 'not allowed';
            }
            return Component ? (
              <Component {...componentProps} {...props} user={currentUser} />
            ) : (
              children({
                ...componentProps,
                ...props,
                user: currentUser,
              })
            );
          }}
        </UserProvider>
      </Layout>
    )}
  </Route>
);

UnauthenticatedRoute.propTypes = {
  component: PropTypes.func,
  componentProps: PropTypes.object,
  layout: PropTypes.func,
  layoutProps: PropTypes.object,
  loading: PropTypes.bool,
  render: PropTypes.func,
  roles: PropTypes.array,
};

// prettier-ignore
export default UnauthenticatedRoute;
