// @flow
import { isWidthUp } from '@material-ui/core/withWidth';

export default {};

export function responsiveThresholds(width) {
  let maxVisiblePanels = 1;
  let panelWidth = 72 * 7;

  let w = width;

  const map = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  };

  if (!isNaN(w)) {
    w = Object.keys(map).reduce((acc, v) => {
      if (width > map[v]) {
        return v;
      }
      return acc;
    }, 'xs');
  }

  if (isWidthUp('xl', w)) {
    maxVisiblePanels = 2;
    panelWidth = 72 * 9;
  } else if (isWidthUp('lg', w)) {
    maxVisiblePanels = 2;
  } else if (isWidthUp('md', w)) {
    maxVisiblePanels = 1;
  } else if (isWidthUp('sm', w)) {
    maxVisiblePanels = 1;
    panelWidth = 72 * 6;
  }
  if (!isNaN(width)) {
    if (width > 384 && width < 768) {
      maxVisiblePanels = 0;
    }
  }

  return {
    maxVisiblePanels,
    panelWidth,
  };
}
