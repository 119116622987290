import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Flex, Toolbar } from '@solar/solar-react';

import { Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import { AppBar } from '../AppBar';
import Fab from './PresetFab';

const useStyles = makeStyles(theme => ({
  title: { flexGrow: 1 },
  toolbar: {},
  extraInset: {
    marginLeft: 72,
    paddingLeft: 0,
  },
}));

const FabView = ({
  children,
  onClickFab,
  title,
  appBarProps,
  actions,
  ...other
}) => {
  const classes = useStyles();
  return (
    <Flex fullColumn {...other}>
      <AppBar disableShadow {...appBarProps}>
        {onClickFab && <Fab onClick={onClickFab} color="secondary" />}
        <Toolbar size="default" fabKeyline={!!onClickFab} />
        <Toolbar size="large" classes={{ root: classes.toolbar }}>
          <Typography
            variant="h6"
            color="inherit"
            noWrap
            className={classNames(classes.title, classes.titleKeyline, {
              [classes.extraInset]: !!onClickFab,
            })}
          >
            {title}
          </Typography>
          {actions}
        </Toolbar>
      </AppBar>
      {children}
    </Flex>
  );
};

FabView.propTypes = {
  children: PropTypes.node,
  onClickFab: PropTypes.func,
  title: PropTypes.string,
  appBarProps: PropTypes.object,
  actions: PropTypes.node,
};

export default FabView;
