import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { Scrollbars } from 'react-custom-scrollbars';

import { makeStyles } from '@material-ui/core/styles';
import { Button, IconButton, Typography, Divider } from '@material-ui/core';

import { Flex } from '@solar/solar-react';

import { ResponsiveAppBar } from '@solar/solar-react';

import {
  CalendarHeader,
  CalendarColumn,
  CalendarDayHeader,
  CalendarHoursColumn,
  CalendarSlot,
} from '@solar/solar-react';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import CalendarSessionItem from './CalendarSessionItem';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    '&.small': {
      backgroundColor: 'red',
    },
    '&.large': {
      backgroundColor: 'green',
    },
  },
  topActions: {
    marginLeft: 20 + 27,
  },
  calendarHeader: {
    backgroundColor: theme.palette.primary[500],
    color: theme.palette.common.white,
    paddingLeft: 0,
    marginLeft: 27,
    height: 79,
  },
  calendarHoursColumn: {
    width: 72,
    backgroundColor: theme.palette.background.paper,
  },
  month: {
    marginLeft: 16,
  },
  toolbar: {
    // Only for calendar, because it has to map on the columns
    paddingRight: 0,
  },
}));

const today = moment();

const calendarStartHour = 6;
const calendarEndHour = 20;
const rows = calendarEndHour - calendarStartHour;
const cellHeight = 64;

const CalendarColumnView = ({
  children,
  className,
  classes: eClasses,
  selectedDates,
  onClickNavButton,
  timeSlots,
  days,
  dayOffSet,
  changeOffset,
  componentMap,
  onClickSlotItem,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  const map = {
    default: slot => (
      <CalendarSessionItem
        title={slot.title}
        start={slot.start}
        end={slot.end}
        status={slot.status}
        onClick={() => {
          onClickSlotItem(slot._id);
        }}
      />
    ),
    ...componentMap,
  };

  return (
    <Flex fullColumn className={classes.root} {...other}>
      <ResponsiveAppBar
        position="static"
        onClickNav={onClickNavButton}
        classes={{ toolbar: classes.toolbar }}
        title={
          <Flex fullColumn>
            <CalendarHeader className={classes.calendarHeader}>
              {selectedDates.map(date => (
                <CalendarDayHeader
                  key={date.toISODate()}
                  date={date.toJSDate()}
                  color={
                    today.isSame(moment(date.toISODate()), 'day')
                      ? 'inherit'
                      : 'inherit'
                  }
                  highlight={today.isSame(moment(date.toISODate()), 'day')}
                />
              ))}
            </CalendarHeader>
          </Flex>
        }
        extraToolbar={
          <Flex centeredRow className={classes.topActions}>
            <Button color="inherit" onClick={changeOffset(-dayOffSet)}>
              VANDAAG
            </Button>
            <IconButton color="inherit" onClick={changeOffset(-days)}>
              <ChevronLeftIcon />
            </IconButton>
            <IconButton color="inherit" onClick={changeOffset(days)}>
              <ChevronRightIcon />
            </IconButton>
            <Typography
              variant="subtitle1"
              color="inherit"
              className={classes.month}
            >
              {selectedDates[0].toFormat('MMMM yyyy, week WW')}
            </Typography>
          </Flex>
        }
      />

      <Scrollbars>
        <Flex>
          <CalendarHoursColumn
            start={calendarStartHour}
            end={calendarEndHour}
            cellHeight={cellHeight}
            className={classes.calendarHoursColumn}
          />
          {selectedDates.map(date => {
            const daySlots = timeSlots[`${date.day} - ${date.month}`];

            return (
              <CalendarColumn
                rows={rows}
                rowHeight={cellHeight}
                key={date}
                highlight={today.isSame(moment(date), 'day')}
              >
                {daySlots &&
                  daySlots.map(slot => {
                    const start = parseInt(slot.start, 10) - calendarStartHour;
                    const end =
                      start +
                      (parseInt(slot.end, 10) - parseInt(slot.start, 10));

                    return (
                      <CalendarSlot
                        cellHeight={cellHeight}
                        start={start}
                        end={end}
                      >
                        {map[slot.type || 'default'](slot)}
                      </CalendarSlot>
                    );
                  })}
              </CalendarColumn>
            );
          })}
        </Flex>
        <Divider />
      </Scrollbars>
    </Flex>
  );
};

export default CalendarColumnView;
