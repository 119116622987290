import PropTypes from 'prop-types';

const TimelineContainer = ({ children, dateString }) => {
  // const { children, dateString, tab } = props;
  const date = dateString ? new Date(dateString) : new Date();

  return children({ date });
};

TimelineContainer.propTypes = {
  children: PropTypes.node,
  dateString: PropTypes.string.required,
  tab: PropTypes.string.required,
};

export default TimelineContainer;
