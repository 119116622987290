import React from 'react';
import PropTypes from 'prop-types';
import shallowEqual from 'recompose/shallowEqual';

const withCachedProp = ({ dataProp }) => {
  let cache;
  return WrappedComponent =>
    class WithCachedProp extends React.Component {
      static displayName = `withCachedProp(${WrappedComponent.displayName ||
        WrappedComponent.name ||
        'Component'})`;

      static propTypes = {
        loading: PropTypes.bool,
      };

      static defaultProps = {
        loading: false,
      };

      constructor(props) {
        super(props);
        if (props[dataProp] && !cache) {
          cache = props[dataProp];
        }
      }

      componentWillReceiveProps(nextProps) {
        if (
          nextProps[dataProp] &&
          nextProps[dataProp] !== cache &&
          !nextProps.loading
        ) {
          cache = nextProps[dataProp];
        }
      }

      shouldComponentUpdate(nextProps) {
        const { [dataProp]: data, ...props } = this.props;
        const { [dataProp]: nextData, ...newProps } = nextProps;

        if (data !== nextData && !nextProps.loading) {
          return true;
        }
        return !shallowEqual(props, newProps);
      }

      render() {
        const cachedProps = {
          [dataProp]: cache,
        };

        return <WrappedComponent {...this.props} {...cachedProps} />;
      }
    };
};

export default withCachedProp;
