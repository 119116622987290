import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { makeStyles } from '@material-ui/core/styles';

import { List, Button, Typography, IconButton } from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { Flex, ResponsiveAppBar } from '@solar/solar-react';

import CalendarListItem from './CalendarListItem';
import CalendarListViewDay from './CalendarListViewDay';

const useStyles = makeStyles(theme => ({
  root: {},
  section: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  subheader: {
    display: 'flex',
    alignItems: 'center',
    height: 48,
    flexShrink: 0,
    paddingLeft: 72,
    color: theme.palette.text.secondary,
  },
  sessionsList: {
    '& > :last-child': {
      borderBottom: 'none',
    },
  },
}));

const CalendarListView = ({
  changeOffset,
  classes: eClasses,
  componentMap,
  days,
  dayOffSet,
  onClickNavButton,
  onClickSlotItem,
  selectedDates,
  showEmptyDays,
  timeSlots,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });

  const map = {
    default: slot => (
      <CalendarListItem
        key={`${slot.date}-${slot.start}-${slot.title}`}
        date={slot.date.toJSDate()}
        start={slot.start}
        end={slot.end}
        title={slot.title}
        status={slot.status}
        onClick={() => {
          props.onClickSlotItem(slot);
        }}
      />
    ),
    ...componentMap,
  };

  return (
    <Flex fullColumn className={classes.root} {...other}>
      <ResponsiveAppBar
        position="static"
        onClickNav={onClickNavButton}
        classes={{ toolbar: classes.toolbar }}
        title={<Flex fullColumn />}
        extraToolbar={
          <Flex centeredRow className={classes.topActions}>
            <Button color="inherit" onClick={changeOffset(-dayOffSet)}>
              VANDAAG
            </Button>
            <IconButton color="inherit" onClick={changeOffset(-1)}>
              <ChevronLeftIcon />
            </IconButton>
            <IconButton color="inherit" onClick={changeOffset(1)}>
              <ChevronRightIcon />
            </IconButton>
            <Typography
              variant="subtitle1"
              color="inherit"
              className={classes.month}
            >
              {selectedDates[0].toFormat('ccc dd MMMM yyyy, week WW')}
            </Typography>
          </Flex>
        }
      />

      <Scrollbars>
        <List className={classes.sessionsList}>
          {selectedDates.map(date => {
            const daySlots = timeSlots[`${date.day} - ${date.month}`];

            if (daySlots && daySlots.length > 1) {
              return (
                <CalendarListViewDay date={date}>
                  <List className={classes.sessionsList}>
                    {daySlots.map(slot => map[slot.type || 'default'](slot))}
                  </List>
                </CalendarListViewDay>
              );
            }
            if (showEmptyDays) {
              return <CalendarListViewDay date={date} />;
            }
            return null;
          })}
        </List>
      </Scrollbars>
    </Flex>
  );
};

CalendarListView.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  componentMap: PropTypes.object,
  showEmptyDays: PropTypes.bool,
};

CalendarListView.defaultProps = {
  className: '',
  showEmptyDays: true,
  componentMap: {},
};

export default CalendarListView;
