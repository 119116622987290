import geocoder from 'google-geocoder';

const KEY = 'AIzaSyCSM46stvKY8VakTahd2zQOQXe3nGBcU1U';
const options = {
  key: KEY, // for Mapquest, OpenCage, Google Premier
};

const geo = geocoder(options);

const promiseFn = location => new Promise((resolve, reject) => {
    geo.find(location, (err, data) => {
      if (err) {
        reject(err);
      }
      resolve(data);
    });
  });

export default { geocode: promiseFn };
