import { toDate, addHours } from 'date-fns';

export default function eachHourOfInterval(dirtyInterval, options) {
  if (arguments.length < 1) {
    throw new TypeError(`1 argument required, but only ${arguments.length} present`);
  }

  const interval = dirtyInterval || {};
  const startDate = toDate(interval.start);
  const endDate = toDate(interval.end);

  const endTime = endDate.getTime();

  // Throw an exception if start date is after end date or if any date is `Invalid Date`
  if (!(startDate.getTime() <= endTime)) {
    throw new RangeError('Invalid interval');
  }

  const timestamps = [];

  let currentDate = startDate;

  const step = options && 'step' in options ? Number(options.step) : 1;
  if (step < 1 || isNaN(step))
    throw new RangeError('`options.step` must be a number greater than 1');

  while (currentDate.getTime() <= endTime) {
    timestamps.push(toDate(currentDate));
    currentDate = addHours(currentDate, 1);
  }

  return timestamps;
}
