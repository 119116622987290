import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  ScrollableContent,
  ResponsiveAppBar,
  LightTextField,
  SplitView,
} from '@solar/solar-react';

import { List, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { makeStyles } from '@material-ui/core/styles';
import getRandomUsers from './stubs/randomUsers';
import PersonListItem from './PersonListItem';

import Calendar from './Calendar';

const useStyles = makeStyles(theme => ({
  root: {},
  calendar: {
    width: '100%',
  },
  appBar: {
    boxShadow: 'none',
    backgroundColor: theme.palette.primary[600],
  },
  topToolbar: {
    height: theme.heights.toolbarTop,
    justifyContent: 'space-between',
  },
  tabAppBar: {
    height: theme.heights.toolbarBottom,
    justifyContent: 'flex-end',
    padding: '0 24px',
    zIndex: 'auto',
  },
  tabButton: {
    minWidth: 'initial',
  },
  border: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  searchBox: {
    marginRight: 24,
  },
  fab: {
    display: 'none', // zIndex issue, if we want this it has to be pos fixed
    position: 'absolute',
    right: -56 / 2,
    bottom: -28,
    zIndex: theme.zIndex.appBar + 1,
    backgroundColor: theme.palette.primary[400],
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary[300],
    },
  },
}));

const houseKeepers = getRandomUsers(24);

const CalendarView = ({
  onClickSlotItem,
  timeSlots,
  allDates,
  componentMap,
  ...other
}) => {
  const classes = useStyles();
  const [state, setState] = useState({
    sideOpen: false,
  });
  const { sideOpen, activeCalendar } = state;

  const openCalendar = id => {
    setState({ sideOpen: true, activeCalendar: id });
  };

  const handleCloseSide = () => {
    setState({ sideOpen: false });
  };

  return (
    <SplitView
      onClose={handleCloseSide}
      open={sideOpen}
      sideContent={
        <Calendar
          onClickSlotItem={onClickSlotItem}
          className={classes.calendar}
          timeSlots={timeSlots}
          allDates={allDates}
          componentMap={componentMap}
        />
      }
      {...other}
    >
      <Flex fullColumn>
        <ResponsiveAppBar
          title={
            <LightTextField
              fullWidth
              placeholder="Zoek een persoon"
              className={classes.searchBox}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="inherit" />
                  </InputAdornment>
                ),
              }}
            />
          }
          disableShadow
        />

        <ScrollableContent classes={{ root: classes.border }}>
          <List>
            <div>
              {houseKeepers.map(({ _id, username, avatarUrl }) => (
                <PersonListItem
                  active={activeCalendar === _id}
                  key={username}
                  username={username}
                  avatarUrl={avatarUrl}
                  onClick={() => {
                    openCalendar(_id);
                  }}
                />
              ))}
            </div>
          </List>
        </ScrollableContent>
      </Flex>
    </SplitView>
  );
};

CalendarView.propTypes = {
  allDates: PropTypes.array.isRequired,
  componentMap: PropTypes.object,
  onClickNavButton: PropTypes.func,
  onClickSlotItem: PropTypes.func.isRequired,
  timeSlots: PropTypes.object.isRequired,
};

/* prettier-ignore */
export default CalendarView;
