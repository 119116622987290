import React from 'react';
import PropTypes from 'prop-types';
import Route from 'react-router-dom/Route';
import Redirect from 'react-router-dom/Redirect';

import UserProvider from '../UserProvider';

const allowedRoles = [];

const AuthenticatedRoute = ({
  accounts,
  component: Component,
  children,
  roles,
  componentProps,
}) => (
  <Route>
    {props => (
      <UserProvider accounts={accounts}>
        {({ currentUser }) => {
          if (!currentUser) {
            return <Redirect to={{ pathname: '/login' }} />;
          }

          const rolesToCheck = roles || allowedRoles;
          if (rolesToCheck && rolesToCheck.length && rolesToCheck.length > 0) {
            const currentRole = currentUser.role ? currentUser.role : '';
            // with role mgmt
            if (rolesToCheck.includes(currentRole)) {
              return Component ? (
                <Component
                  {...componentProps}
                  {...props}
                  user={currentUser}
                  currentRole={currentRole}
                />
              ) : (
                children({
                  ...componentProps,
                  ...props,
                  currentRole,
                  user: currentUser,
                })
              );
            }
            return 'not allowed';
          }
          return Component ? (
            <Component {...componentProps} {...props} user={currentUser} />
          ) : (
            children({ ...componentProps, ...props, user: currentUser })
          );
        }}
      </UserProvider>
    )}
  </Route>
);

AuthenticatedRoute.propTypes = {
  component: PropTypes.func,
  loading: PropTypes.bool,
  render: PropTypes.func,
  roles: PropTypes.array,
};

// prettier-ignore
export default AuthenticatedRoute;
