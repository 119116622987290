import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { isWidthDown } from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { Tabs, Tab } from '@material-ui/core';

import { Flex, ScrollableContent, ResponsiveAppBar } from '@solar/solar-react';

const useStyles = makeStyles(theme => ({
  root: {},
  title: {},
  tabs: {
    width: '100%',
  },
  bigTabs: {
    minHeight: theme.heights.toolbarBottom,
  },
  appBar: {
    position: 'static', // TODO: remove after SimpleAppBar className prop fix
    zIndex: 2,
    boxShadow: 'none',
  },
  extraToolbar: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  evenWidth: {
    // Rules to make Tabs all equal width without shenanigans:
    flexBasis: 0,
    width: 0,
    minWidth: 'initial',
  },
  scrollContainer: {},
}));

const TabView = ({
  children,
  history,
  tabs,
  appBarProps,
  width,
  fullWidth,
  evenWidth,
  ...props
}) => {
  const { extraToolbar, ...otherAppBarProps } = appBarProps;

  const classes = useStyles();

  const [state, setState] = useState({
    activeTab: null,
  });

  const activeTab =
    state.activeTab || props.default || (tabs && Object.keys(tabs)[0]);

  const handleTabChange = (event, activeTab) => {
    setState({ activeTab });
  };
  const Comp = tabs[activeTab].component;
  const scrollable = !tabs[activeTab].dontWrap;
  const isPhone = isWidthDown('xs', width);

  const TabsC = (
    <Tabs
      fullWidth={fullWidth}
      {...props}
      value={activeTab}
      indicatorColor="secondary"
      onChange={handleTabChange}
      classes={{
        root: classNames(classes.tabs, !isPhone && classes.bigTabs),
      }}
    >
      {Object.keys(tabs).map(
        t =>
          tabs[t].label && (
            <Tab
              classes={{ root: !isPhone && classes.bigTabs }}
              className={classNames(
                classes.tabButton,
                fullWidth && evenWidth && classes.evenWidth,
              )}
              label={tabs[t].label}
              value={t}
            />
          ),
      )}
    </Tabs>
  );

  return (
    <Flex fullColumn classes={{ root: classes.root }} {...props}>
      <ResponsiveAppBar
        titleOnTop
        extraToolbar={!isPhone && TabsC}
        classes={{ extraToolbar: classes.extraToolbar }}
        {...otherAppBarProps}
      />
      {isPhone && TabsC}
      {scrollable ? (
        <ScrollableContent classes={{ root: classes.scrollContainer }}>
          <Comp />
        </ScrollableContent>
      ) : (
        <Comp />
      )}
    </Flex>
  );
};

TabView.propTypes = {
  default: PropTypes.string,
  evenWidth: PropTypes.bool,
  fullWidth: PropTypes.bool,
  tabs: PropTypes.object.isRequired,
};

TabView.defaultProps = {
  fullWidth: true,
  evenWidth: true,
};

export default TabView;
