import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, TextField, Button } from '@material-ui/core';

import Link from 'react-router-dom/Link';

import { Flex } from '@solar/solar-react';

const useStyles = makeStyles(theme => ({
  button: {
    width: '100%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    boxShadow: 'none',
  },
  spaceTop: {
    marginTop: theme.spacing(2),
  },
}));
const LoginPage = ({ t, history, accounts }) => {
  const classes = useStyles();

  const [state, setState] = useState({
    errors: null,
    form: {
      email: '',
      password: '',
    },
  });
  const { errors, form } = state;

  const handleSubmit = () => {
    const result = accounts.login(form);
    if (result && result.error) {
      setState({ errors: { email: result.error } });
    } else {
      history.push('/');
    }
  };

  const handleFieldChange = name => event => {
    const { value } = event.target;
    if (value || value.length === 0) {
      setState(({ errors: e, form: f }) => ({
        form: {
          ...f,
          [name]: value,
        },
        errors: {
          ...e,
          [name]: null,
        },
      }));
    }
  };

  return (
    <Fragment>
      <TextField
        name="email"
        id="email"
        fullWidth
        margin="normal"
        label={t('email address').capitalizeFirstLetter()}
        autoFocus
        error={errors && !!errors.email}
        helperText={errors && errors.email}
        onChange={handleFieldChange('email')}
        value={form.email}
        required={false}
      />
      <TextField
        name="password"
        id="password"
        fullWidth
        margin="normal"
        inputProps={{ type: 'password' }}
        label={t('password').capitalizeFirstLetter()}
        error={errors && !!errors.password}
        helperText={errors && errors.password}
        onChange={handleFieldChange('password')}
        value={form.password}
        required={false}
      />
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        type="submit"
      >
        {t('signIn')}
      </Button>
      <Flex centeredRow justifyContent="space-between">
        <Typography type="body1">
          <Link to="/signup">{t('loginPage.signupCTALink')}</Link>
        </Typography>
        <Typography type="body1">
          <Link to="/reset">{t('loginPage.resetCTALink')}</Link>
        </Typography>
      </Flex>
      <Typography type="body1" className={classes.spaceTop}>
        {t('loginPage.autoCTAText')}{' '}
        <Link to="/auto">{t('loginPage.autoCTALink')}</Link>
      </Typography>
    </Fragment>
  );
};

LoginPage.propTypes = {
  accounts: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default LoginPage;
