import store from 'store2';
import geocoder from './geocoder';

export default async location => {
  let locations = store('google-geo');
  if (!locations) {
    locations = {};
  }
  if (!locations[location]) {
    const geocoded = await geocoder.geocode(location);
    if (geocoded && geocoded[0]) {
      locations = {
        ...locations,
        [location]: geocoded[0],
      };
      store('google-geo', locations);
    }
  }
  return locations[location];
};
