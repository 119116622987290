import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Flex } from '@solar/solar-react';

import { ListItem, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'flex-start',
  },
  timeColumn: {
    width: 40,
    flexShrink: 0,
    padding: '4px 0',
    marginRight: 16,
  },
  dayOfMonth: {
    color: theme.palette.primary.light,
  },
  content: {
    flexGrow: 1,
    minWidth: 0,
    paddingRight: 24,
  },
  heading: {
    marginBottom: 8,
  },
  [theme.breakpoints.up('sm')]: {
    root: {
      paddingRight: 24,
    },
    content: {
      paddingLeft: 8,
    },
  },
}));

const CalendarListViewDay = ({
  children,
  className,
  date,
  end,
  renderDate,
  start,
  status,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <ListItem
      component="div"
      divider
      classes={{
        root: classes.root,
      }}
      {...other}
    >
      <Flex flexDirection="column" className={classes.timeColumn}>
        <Typography variant="caption">{date.toFormat('ccc')}</Typography>
        <Typography variant="h5" className={classes.dayOfMonth}>
          {date.toFormat('dd')}
        </Typography>
        <Typography variant="caption">{date.toFormat('MMM')}</Typography>
      </Flex>
      <div className={classes.content}>{children}</div>
    </ListItem>
  );
};

CalendarListViewDay.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  date: PropTypes.instanceOf(Date).isRequired,
  end: PropTypes.string,
  start: PropTypes.string,
  status: PropTypes.string,
};

CalendarListViewDay.defaultProps = {
  children: '',
  className: '',
  end: '',
  start: '',
  status: 'open',
};

export default CalendarListViewDay;
