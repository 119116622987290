import React, { useState, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  addDays,
  format,
  startOfDay,
  addHours,
  differenceInHours,
} from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import duLocale from 'date-fns/locale/nl';

import {
  Button,
  Typography,
  LinearProgress,
  ListItem,
} from '@material-ui/core';
import TimelineIcon from '@material-ui/icons/DateRange';
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  DateNav,
  ElementWidth,
  Flex,
  ResponsiveAppBar,
  SlotItem,
  eachHourOfInterval,
  Timeline,
  TimelineBody,
  TimelineGridMarkings,
  TimelineHeadings,
  TimelineMarkings,
  TimelineRow,
  TimelineRowBody,
  TimelineRowHeader,
  Timeslot,
} from '@solar/solar-react';

const useStyles = makeStyles(theme => ({
  root: {},
  elementWidth: {
    height: '100%',
    width: '100%',
  },
  emptyState: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(6),
    width: '100%',
  },
  emptyState__content: {
    alignItems: 'center',
    color: theme.palette.text.hint,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '500px',
  },
  emptyState__icon: {
    marginBottom: theme.spacing(2),
  },
  emptyState__text: {
    color: theme.palette.text.disabled,
    textAlign: 'center',
  },
  flexTop: {
    flex: '0 0 auto',
  },
  flexBottom: {},
  flexLeft: {
    width: 360,
  },
  flexRight: {
    flexGrow: 1,
  },
  title: {
    flexShrink: 0,
  },
  extraToolbar: {
    padding: 0,
    paddingTop: 16,
  },
  placeholder: {
    backgroundColor: theme.palette.background.appBar,
    width: 360,
  },
  timeline: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  timelineMarkings: {
    borderBottom: 'none',
  },
  timelineRowHeader: {
    borderRight: 'none',
  },
  timelineHeadings: {
    height: '100%',
  },
  progressBar: {
    height: '0.5rem',
    position: 'absolute',
    width: '100%',
    top: 144,
    zIndex: 100,
  },
  rowBody: {
    overflow: 'visible',
  },
  slot: {
    zIndex: 1,
  },
}));

const TimelineComponent = ({
  dateItems,
  dateLabel,
  dateProp,
  loading,
  onChangeDay,
  onClickSlot,
  onClickToday,
  onNextDay,
  onPreviousDay,
  timelineSettings,
  unitsProp,
  ...other
}) => {
  const classes = useStyles();
  const [date, setDate] = useState(dateProp);
  const [currentUnit, setCurrentUnit] = useState(false);
  const [scrollLeft, setScrollLeft] = useState({});
  const [width, setWidth] = useState(72);
  const scrollbarsBody = useRef(null);
  const scrollbarsMarkings = useRef(null);

  function scrollToUnit(unit) {
    setCurrentUnit(unit);
  }
  if (!timelineSettings.end) {
    timelineSettings.end = timelineSettings.add(
      timelineSettings.start,
      timelineSettings.unitsToRender,
    );
  }
  const timeline = timelineSettings;
  let units = [];
  if (unitsProp && unitsProp.length > 0) {
    units = unitsProp;
  } else {
    units = timeline
      .intervalFunction({ start: timeline.start, end: timeline.end })
      .filter((unit, index) => index % timeline.unitsToRenderPerColumn === 0)
      .map(unit => ({ ...unit, label: format(unit, 'HH:mm') }));
  }

  const onNextDayTemp = () => {
    setDate(addDays(date, 1));
  };

  const onPreviousDayTemp = () => {
    setDate(addDays(date, -1));
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={duLocale}>
      <Flex fullColumn className={classes.root} {...other}>
        <Flex className={classes.flexTop}>
          <Flex className={classes.placeholder} fullColumn />
          <Flex className={classes.flexRight}>
            <ResponsiveAppBar
              position="static"
              titleOnTop
              title={
                <Flex>
                  <DateNav
                    classes={{ root: classes.dateNav }}
                    dateLabel={format(date, 'yyyy-MM-dd')}
                    onChange={onChangeDay}
                    onClickNext={onNextDayTemp}
                    onClickPrevious={onPreviousDayTemp}
                  />
                  <Button color="inherit" onClick={onClickToday}>
                    {'Vandaag'}
                  </Button>
                </Flex>
              }
              className={{ root: classes.extraToolbar }}
              extraToolbarProps={{
                classes: {
                  extraToolbar: {
                    backgroundColor: 'black',
                    padding: 0,
                    paddingTop: 16,
                  },
                },
              }}
              extraToolbar={
                <ElementWidth noTresholds className={classes.elementWidth}>
                  {({ width: elementWidth }) => {
                    if (elementWidth && elementWidth > 0) {
                      let newWidth = elementWidth / units.length;

                      if (newWidth < timeline.minWidth) {
                        newWidth = timeline.minWidth;
                      }

                      if (newWidth !== timeline.columnWidth) {
                        setWidth(newWidth);
                      }
                    }

                    return width ? (
                      <TimelineMarkings
                        className={classes.timelineMarkings}
                        height={timeline.rowHeight}
                        onClickUnit={unit => {
                          scrollToUnit(unit);
                        }}
                        onUpdate={scrollLeftNew => {
                          setScrollLeft(scrollLeftNew);
                        }}
                        scrollbarsRef={scrollbarsMarkings}
                        scrollLeft={scrollLeft}
                        units={units}
                        width={Math.floor(
                          timeline.columnWidth * timeline.actualScale,
                        )}
                      />
                    ) : null;
                  }}
                </ElementWidth>
              }
            />
          </Flex>
        </Flex>
        <Fragment>
          {loading && (
            <LinearProgress className={classes.progressBar} color="secondary" />
          )}
          {dateItems && dateItems.length > 0 ? (
            <Flex className={classes.flexBottom}>
              <TimelineHeadings
                timeline={timeline}
                height={timeline.rowHeight}
                className={classes.flexLeft}
                classes={{ root: classes.timelineHeadings }}
              >
                {dateItems.map(dateItem => (
                  <TimelineRow key={dateItem._id} height={timeline.rowHeight}>
                    <TimelineRowHeader
                      classes={{ root: classes.timelineRowHeader }}
                    >
                      <ListItem key={dateItem._id} style={{ padding: '16px' }}>
                        {dateItem.header}
                      </ListItem>
                    </TimelineRowHeader>
                  </TimelineRow>
                ))}
              </TimelineHeadings>
              {timeline.columnWidth && (
                <TimelineBody
                  timeline={timeline}
                  classes={{ root: classes.timelineBody }}
                  currentUnit={currentUnit}
                  scrollbarsRef={scrollbarsBody}
                  scrollLeft={scrollLeft}
                  onUpdate={scrollLeftNew => {
                    setScrollLeft(scrollLeftNew);
                  }}
                >
                  <TimelineGridMarkings
                    onClickUnit={unit => {
                      scrollToUnit(unit);
                    }}
                    units={units}
                    width={Math.floor(
                      timeline.columnWidth * timeline.actualScale,
                    )}
                  >
                    <Timeline timeline={timeline} className={classes.timeline}>
                      {dateItems.map(dateItem => (
                        <TimelineRow
                          key={dateItem._id}
                          height={timeline.rowHeight}
                          className={classes.row}
                        >
                          <TimelineRowBody
                            gutter={4}
                            classes={{ root: classes.rowBody }}
                          >
                            {dateItem.slots.map(slot => {
                              const duration = differenceInHours(
                                slot.end,
                                slot.start,
                              );
                              const startPosition = differenceInHours(
                                slot.start,
                                timeline.start,
                              );
                              return (
                                <Timeslot
                                  duration={duration}
                                  key={slot._id}
                                  left={startPosition * timeline.scale}
                                  startPosition={startPosition}
                                  width={duration * timeline.scale}
                                >
                                  <SlotItem
                                    blocks={slot.blocks}
                                    endLabel={format(slot.end, 'HH:mm')}
                                    color={slot.color}
                                    orientation="horizontal"
                                    startLabel={format(slot.start, 'HH:mm')}
                                    title={slot.title}
                                  />
                                </Timeslot>
                              );
                            })}
                          </TimelineRowBody>
                        </TimelineRow>
                      ))}
                    </Timeline>
                  </TimelineGridMarkings>
                </TimelineBody>
              )}
            </Flex>
          ) : (
            <div className={classes.emptyState}>
              <div className={classes.emptyState__content}>
                <TimelineIcon
                  fontSize="large"
                  className={classes.emptyState__icon}
                />
                <Typography
                  variant="subtitle1"
                  className={classes.emptyState__text}
                >
                  {'Geen items'}
                </Typography>
              </div>
            </div>
          )}
        </Fragment>
      </Flex>
    </MuiPickersUtilsProvider>
  );
};

TimelineComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  dateItems: PropTypes.array.isRequired,
  dateLabel: PropTypes.string.isRequired,
  dateProp: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  onChangeDay: PropTypes.func.isRequired,
  onClickSlot: PropTypes.func.isRequired,
  onClickToday: PropTypes.func.isRequired,
  onNextDay: PropTypes.func.isRequired,
  onPreviousDay: PropTypes.func.isRequired,
  timelineSettings: PropTypes.object.isRequired,
  unitsProp: PropTypes.array.isRequired,
  zoomLevel: PropTypes.number.isRequired,
  zoomScale: PropTypes.object.isRequired,
};

TimelineComponent.defaultProps = {
  dateItems: [],
  dateProp: new Date(),
  loading: false,
  onChangeDay: () => {
    console.log('onChangeDay');
  },
  onClickSlot: () => {
    console.log('onClickSlot');
  },
  onClickToday: () => {
    console.log('onClickToday');
  },
  onNextDay: () => {
    console.log('onNextDay');
  },
  onPreviousDay: () => {
    console.log('onPreviousDay');
  },
  zoomLevel: 1,
  zoomScale: {
    1: 1,
    2: 3,
    3: 6,
  },
  timelineSettings: {
    actualScale: 1,
    add: addHours,
    columnWidth: 72,
    differenceFunction: differenceInHours,
    end: addHours(startOfDay(new Date()), 20),
    intervalFunction: eachHourOfInterval,
    minWidth: 72,
    offset: 24, // in pixels.
    rowHeight: 72,
    scale: 72,
    start: addHours(startOfDay(new Date()), 6),
    timezone: 'Europe/Brussels',
    unit: 'hours',
    unitsToRender: 14,
    unitsToRenderPerColumn: 1,
  },
  unitsProp: [],
  warningsCount: 0,
};
export default TimelineComponent;
