import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import { Card, Typography } from '@material-ui/core';

import TimerIcon from '@material-ui/icons/Timer';
import DoneIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';

import { Flex } from '@solar/solar-react';

const useStyles = makeStyles(theme => ({
  root: {
    height: 'calc(100% - 4px)',
    marginRight: 4,
    marginBottom: 4,
    padding: '8px 16px',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  affordance: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  header: {},
  preserveNoWrap: {
    minWidth: 0,
  },
  content: {
    marginTop: 8,
  },
  statusIcon: {
    marginTop: 2,
  },
}));

const CalendarSessionItem = ({
  children,
  classes: eClasses,
  className,
  end,
  onClick,
  start,
  status,
  title,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  let statusIcon = '';

  if (status === 'busy') {
    statusIcon = <TimerIcon color="inherit" className={classes.statusIcon} />;
  } else if (status === 'finished') {
    statusIcon = <DoneIcon color="inherit" className={classes.statusIcon} />;
  } else if (status === 'warning') {
    statusIcon = (
      <WarningIcon color="secondary" className={classes.statusIcon} />
    );
  }

  return (
    <Card
      onClick={onClick}
      className={classNames(
        classes.root,
        onClick && classes.affordance,
        className,
      )}
      {...other}
    >
      <Flex justifyContent="space-between" className={classes.header}>
        <div className={classes.preserveNoWrap}>
          <Typography color="inherit" variant="body2" noWrap title={title}>
            {title}
          </Typography>
          <Typography color="inherit" variant="caption">
            {`${start} — ${end}`}
          </Typography>
        </div>
        {statusIcon}
      </Flex>
      {children && <div className={classes.content}>{children}</div>}
    </Card>
  );
};

CalendarSessionItem.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  end: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  start: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['open', 'busy', 'finished', 'warning']),
  title: PropTypes.string.isRequired,
};

CalendarSessionItem.defaultProps = {
  children: '',
  className: '',
  onClick: null,
  status: 'open',
};

export default CalendarSessionItem;
