

// TODO: add light theme typography colors to basic theme
//       for use on dark ink backgrounds
//       (figure out a good way to merge these + propose on MUI?)

export const extraColors = {
  icon: 'rgba(0, 0, 0, 0.38)',
  lightDivider: 'rgba(0, 0, 0, 0.075)',
  mediumDivider: 'rgba(0, 0, 0, .04)',
  text: {
    lightSecondary: 'rgba(255, 255, 255, .7)',
  },
};

//// ACTUAL BASE:

// TODO: Improve (potential breaking changes):
//
//   - singular instead of plural? (eg increments => increment)
//   - phone instead of mobile?
//

export const increments = {
  typography: 4,
  mobile: 56,
  tablet: 64,
};

export const keylines = {
  mobileContent: 72,
  tabletContent: 80,
};

export const widths = {
  creationDialog: increments.tablet * 7,
  tabletActionBar: increments.tablet * 5,
};

export const heights = {
  toolbarTopMobile: increments.mobile,
  toolbarBottomMobile: keylines.mobileContent,
  toolbarTop: increments.tablet,
  toolbarBottom: keylines.tabletContent,
};

export const radii = {
  typography: increments.typography,
};

const themeBase = {
  increments,
  keylines,
  widths,
  heights,
  radii,
};

export default themeBase;
