import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import TimerIcon from '@material-ui/icons/Timer';
import DoneIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';

import { Flex } from '@solar/solar-react';

import { ListItem, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'flex-start',
  },
  timeColumn: {
    width: 80,
    flexShrink: 0,
    padding: '4px 0',
    marginRight: 8,
  },
  dayOfMonth: {
    color: theme.palette.primary.light,
  },
  content: {
    flexGrow: 1,
    minWidth: 0,
    paddingRight: 12,
  },
  heading: {
    marginBottom: 8,
  },
  [theme.breakpoints.up('sm')]: {
    root: {
      paddingLeft: 12,
      paddingRight: 12,
    },
    content: {
      // paddingLeft: 4,
    },
  },
}));

const CalendarListItem = ({
  children,
  className,
  date,
  end,
  title,
  renderDate,
  start,
  status,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  let statusIcon = '';

  if (status === 'busy') {
    statusIcon = <TimerIcon color="primary" />;
  } else if (status === 'approved') {
    statusIcon = <DoneIcon color="primary" />;
  } else if (status === 'warning') {
    statusIcon = <WarningIcon color="secondary" />;
  }
  return (
    <ListItem
      component="li"
      classes={{
        root: classes.root,
      }}
      {...other}
    >
      <Flex flexDirection="row" className={classes.timeColumn}>
        <Typography variant="caption">{`${start} — ${end}`}</Typography>
      </Flex>
      <Typography color="inherit" variant="body2" noWrap title={title}>
        {title}
      </Typography>
      <div className={classes.content}>{children}</div>
      {statusIcon}
    </ListItem>
  );
};

CalendarListItem.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  date: PropTypes.instanceOf(Date).isRequired,
  end: PropTypes.string,
  start: PropTypes.string,
  status: PropTypes.string,
};

CalendarListItem.defaultProps = {
  children: '',
  className: '',
  end: '',
  start: '',
  status: 'open',
  renderDate: true,
};

export default CalendarListItem;
