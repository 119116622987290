import faker from 'faker';

export default function getRandomUsers(amount = 10, locale = 'nl') {
  const randomUsers = [];
  faker.locale = locale;

  for (let i = 0; i < amount; i += 1) {
    randomUsers.push({
      _id: i,
      username: faker.fake('{{name.firstName}} {{name.lastName}}'),
      avatarUrl: faker.image.avatar(),
    });
  }

  return randomUsers;
}
