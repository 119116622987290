import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Redirect from 'react-router-dom/Redirect';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import {
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  TextField,
  Button,
} from '@material-ui/core';

import { Flex, AlignRight } from '@solar/solar-react';

import LangSwitch from './LangSwitch';

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    boxShadow: 'none',
  },
  formControl: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  spaceTop: {
    marginTop: theme.spacing(2),
  },
  spaceLeft: {
    marginLeft: 36,
    paddingRight: 36,
  },
}));

const OnboardingPage = ({
  accounts,
  history,
  classes: eClasses,
  loggedIn,
  t,
  lang,
  onChangeLang,
}) => {
  const classes = useStyles({ classes: eClasses });

  const [state, setState] = useState({
    errors: null,
    form: {
      email: '',
    },
    first: true,
    mailSent: false,
  });

  const { errors, form, first, mailSent } = state;

  const { email } = form;

  const handleSend = () => {
    const result = accounts.sendMail(form);
    if (result && result.error) {
      setState({ errors: { email: result.error } });
    } else {
      setState({ mailSent: true });
    }
  };

  const handleResend = () => {};

  const handleRegister = () => {
    const [username, password] = email.split('@');

    if (username && password) {
      const result = accounts.register({ email, username, password });
      if (result && result.error) {
        setState({ errors: { email: result.error } });
      } else {
        history.push('/');
      }
    } else {
      setState({ errors: { email: 'Invalid Email' } });
    }
  };

  const handleSubmit = () => {
    // find user with email
    const result = accounts.login({ email });
    if (result && result.error) {
      setState({ errors: { email: result.error } });
    } else {
      history.push('/');
    }
  };

  const handleFieldChange = name => event => {
    const { value } = event.target;
    if (value || value.length === 0) {
      setState(({ errors: e, form: f }) => ({
        form: {
          ...f,
          [name]: value,
        },
        errors: {
          ...e,
          [name]: null,
        },
      }));
    }
  };

  if (loggedIn) {
    return <Redirect to={{ pathname: '/' }} />;
  }

  return (
    <Fragment>
      <LangSwitch lang={lang} onChange={onChangeLang} />
      <FormControl component="fieldset" className={classes.formControl}>
        <RadioGroup
          aria-label="Selecteer een kantoor in uw regio"
          name="office"
          className={classes.group}
          value={first ? 'first' : 'notFirst'}
          onChange={event => {
            setState({ first: event.target.value === 'first' });
          }}
        >
          <FormControlLabel
            value="first"
            control={<Radio />}
            label={t('firstVisit')}
          />
          <FormControlLabel
            value="notFirst"
            control={<Radio />}
            label={t('firstVisitNot')}
          />
        </RadioGroup>
      </FormControl>
      {first ? (
        <Fragment>
          <Typography variant="h6">{t('registerTitle')}</Typography>
          <Typography
            variant="body2"
            className={classNames(classes.spaceTop, classes.spaceLeft)}
          >
            {t('registerBody')}
          </Typography>
        </Fragment>
      ) : (
        <Typography
          variant="body2"
          className={classNames(classes.spaceTop, classes.spaceLeft)}
        >
          {mailSent ? t('mailSent') : t('login')}
        </Typography>
      )}
      {!mailSent && (
        <TextField
          name="email"
          id="email"
          fullWidth
          className={classNames(classes.spaceLeft)}
          margin="normal"
          label={t('email address').capitalizeFirstLetter()}
          autoFocus
          error={errors && !!errors.email}
          helperText={errors && errors.email}
          onChange={handleFieldChange('email')}
          value={form.email}
          required={false}
        />
      )}

      {mailSent ? (
        <Fragment>
          <AlignRight>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={handleResend}
              type="submit"
            >
              {t('sendAgain')}
            </Button>
          </AlignRight>
          <Button
            className={classes.button}
            variant="text"
            onClick={handleSubmit}
            type="submit"
          >
            skip
          </Button>
        </Fragment>
      ) : (
        <Flex centeredRow justifyContent="space-between">
          <div />
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={first ? handleRegister : handleSend}
            type="submit"
          >
            {first ? t('next') : t('sendLink')}
          </Button>
        </Flex>
      )}
    </Fragment>
  );
};

OnboardingPage.propTypes = {
  accounts: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loggedIn: PropTypes.bool.isRequired,
};

export default OnboardingPage;
