import * as R from 'ramda'
import { createBrowserHistory as createHistory } from 'history';
import queryString from 'query-string';

export default function(detailPanes) {
  const history = createHistory();

  const _cacheQS = {};
  const _cacheP = {};

  const queryStringParse = search => {
    if (true || !_cacheQS[search]) {
      _cacheQS[search] = queryString.parse(search);
    }
    return _cacheQS[search];
  };

  const parsePanels = search => {
    if (true || !_cacheP[search]) {
      const q = queryStringParse(search);
      const panels = [];
      if (q.detail) {
        if (typeof q.detail === 'string' && q.detail.startsWith('[')) {
          q.detail = JSON.parse(q.detail);
        }
        if (!Array.isArray(q.detail)) {
          q.detail = [q.detail];
        }
        q.detail.forEach(element => {
          panels.push(queryStringParse(element));
        });
      }
      _cacheP[search] = panels;
    }
    return _cacheP[search];
  };

  const stringifyPanels = panels => {
    const queryarray = [];
    panels.forEach(panel => {
      queryarray.push(queryString.stringify(panel));
    });

    if (queryarray.length > 1) {
      return JSON.stringify(queryarray);
    }
    return queryarray[0];
  };

  const pushHistory = panels => {
    const queryParams = queryStringParse(history.location.search);
    if (panels && panels.length) {
      queryParams.detail = stringifyPanels(panels);
    } else {
      delete queryParams.detail;
    }
    history.push({
      ...history.location,
      search: queryString.stringify({
        ...queryParams,
      }),
    });
  };

  Object.setPrototypeOf(history, {
    setProperty(key, prop, value) {
      if (detailPanes.includes(key)) {
        const panels = parsePanels(history.location.search);
        if (panels && panels.length) {
          const index = R.findIndex(R.propEq('context', key), panels);
          // panel already exists
          if (index > -1) {
            panels[index][prop] = value;
            pushHistory(panels);
          }
        }
      }
    },
    addQueryParams(params) {
      const queryParams = queryStringParse(history.location.search);
      Object.keys(params).forEach(key => {
        queryParams[key] = params[key];
      });
      history.push({
        ...history.location,
        search: queryString.stringify({
          ...queryParams,
        }),
      });
    },
    addQuery(key, value, reset = false, root = false) {
      const queryParams = queryStringParse(history.location.search);
      if (detailPanes.includes(key)) {
        let panel = {
          context: key,
          id: value,
        };
        let panels = parsePanels(history.location.search);
        if (panels && panels.length) {
          const index = R.findIndex(R.propEq('context', key), panels);
          let changed = false;
          if (index > -1) {
            // panel is already open
            // modify only the id, or reset completeley
            if (reset) {
              changed = true;
            } else if (panels[index].id !== value) {
              panel = {
                ...panels[index],
                ...panel,
              };
              changed = true;
            }

            if (root) {
              panels = [panel];
            } else {
              panels[index] = panel;
              // if subPanels are open, close them if id has changed
              if (index + 1 < panels.length && changed) {
                panels = R.slice(0, -(panels.length - (index + 1)), panels);
              }
            }
          } else if (root) {
            // there are panels open, but not this one
            // add new panel to end
            panels = [panel];
          } else {
            panels.push(panel);
          }
        } else {
          // there was no panel open, start from scratch
          panels = [panel];
        }
        queryParams.detail = stringifyPanels(panels);
      } else {
        queryParams[key] = value;
      }

      history.push({
        ...history.location,
        search: queryString.stringify({
          ...queryParams,
        }),
      });
    },
    removeQuery(key) {
      const queryParams = queryStringParse(history.location.search);
      if (detailPanes.includes(key)) {
        let panels = parsePanels(history.location.search);
        if (panels && panels.length) {
          const index = R.findIndex(R.propEq('context', key), panels);
          if (index > -1) {
            // panel is open
            // remove panel AND all subsequent panels
            panels = R.slice(0, -(panels.length - index), panels);
          } else {
            // there are panels open, but not this one
          }
        } else {
          // there was no panel open, nothing to be removed
        }
        queryParams.detail = stringifyPanels(panels);
      } else {
        delete queryParams[key];
      }

      history.push({
        ...history.location,
        search: queryString.stringify({
          ...queryParams,
        }),
      });
    },
    getQuery(key) {
      const queryParams = queryStringParse(history.location.search);
      return queryParams[key];
    },
    getPanels() {
      return parsePanels(history.location.search);
    },
    getIdFromContext(context) {
      // TODO optimize

      const selectedPanel = this.getPanels().filter(p => p.context === context);
      const selectedId = selectedPanel ? selectedPanel[0] && selectedPanel[0].id : '';
      return selectedId;
    },
    getQueryParam(key) {
      const queryParams = queryStringParse(history.location.search);
      return queryParams[key];
    },
  });

  return history;
}
