import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { IconButton, Avatar, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import ParticipantsIcon from '@material-ui/icons/Group';

import { Flex, ChatOutput, ResponsiveAppBar } from '@solar/solar-react';

import ChatToolbar from './ChatToolbar';
import Conversation from './Conversation';

const useStyles = makeStyles(theme => ({
  root: {},
  title: {
    minWidth: 0, // preserve noWrap
    marginLeft: 12,
  },
  avatar: {
    marginRight: 16,
  },
  output: {
    padding: '12px 12px 0 12px',
  },
}));

const ChatDetails = ({
  children,
  componentMap,
  onClickNavButton,
  onClickInfo,
  user,
  conversation,
  detailView,
  width,
  ...other
}) => {
  const classes = useStyles();
  const otherUser = conversation.messages
    .slice()
    .reverse()
    .find(m => m.user._id !== user._id).user;

  return (
    <Flex fullColumn className={classes.root} {...other}>
      <ResponsiveAppBar
        position="static"
        onClickNav={onClickNavButton}
        title={
          <Flex centeredRow className={classes.title}>
            <Avatar src={otherUser.avatarUrl} className={classes.avatar} />
            <Typography variant="h6" color="inherit" noWrap>
              {otherUser.username}
            </Typography>
          </Flex>
        }
        actions={
          <Flex centeredRow>
            <IconButton color="inherit">
              <ParticipantsIcon />
            </IconButton>
            <IconButton color="inherit" onClick={onClickInfo}>
              <InfoIcon />
            </IconButton>
          </Flex>
        }
      />
      <ChatOutput classes={{ output: classes.output }}>
        <Conversation
          messages={conversation.messages}
          user={user._id}
          componentMap={componentMap}
        />
      </ChatOutput>
      <ChatToolbar />
    </Flex>
  );
};

ChatDetails.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  componentMap: PropTypes.object,
  conversation: PropTypes.array.isRequired,
  onClickInfo: PropTypes.func,
  onClickNavButton: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

ChatDetails.defaultProps = {
  children: '',
};

export default ChatDetails;
