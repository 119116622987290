import store from 'store2';

const defaultUsers = [
  {
    username: 'jens_',
    email: 'jens.verbeken@solarapps.be',
    avatarUrl:
      'https://git.solarapps.be/uploads/-/system/user/avatar/3/avatar.png',
  },
  {
    username: 'psoudan',
    email: 'pieter.soudan@solarapps.be',
    avatarUrl:
      'http://2.bp.blogspot.com/-4wyqtFdBTI4/T-9AhgGDjkI/AAAAAAAAARo/7S3Oq2gJC9c/s1600/tom-cruise-acting-crazy.jpg',
  },
];

export default (users, providedStore = store) => {
  const st = providedStore.namespace('accounts');
  st('allUsers', users || defaultUsers);
  return {
    get allUsers() {
      return st('allUsers');
    },
    get currentUser() {
      return st('currentUser');
    },
    login(userToLogin) {
      const userExists = this.allUsers.find(
        userToTest => userToTest.email === userToLogin.email,
      );
      if (userExists) {
        return st('currentUser', userExists);
      }
      return { error: 'User does not exist' };
    },
    logout() {
      return st('currentUser', null);
    },
    register(newUser) {
      const allUsers = this.allusers;

      const userAlreadyExists = allUsers.find(
        userToTest => userToTest.email === newUser.email,
      );
      if (userAlreadyExists) {
        return { error: 'Email address already exists' };
      }

      const newUserWithAvatar = {
        ...newUser,
        avatarUrl: `https://api.adorable.io/avatars/285/${Math.floor(
          Math.random() * 10,
        )}.png`,
      };

      st('allUsers', [...allUsers, newUserWithAvatar]);
      st('currentUser', newUserWithAvatar);
      return false;
    },
    sendMail(userToLogin) {
      if (!userToLogin.email) {
        return { error: 'Invalid Email' };
      }
      const userExists = this.allUsers.find(
        userToTest => userToTest.email === userToLogin.email,
      );
      if (!userExists) {
        return { error: 'User does not exist' };
      }
      return true;
    },
  };
};
