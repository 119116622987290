import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import SimpleSchema from 'simpl-schema';
import Link from 'react-router-dom/Link';
import { makeStyles } from '@material-ui/core/styles';

import { Typography, TextField, Button } from '@material-ui/core';

const signupSchema = new SimpleSchema({
  username: {
    type: String,
    min: 3,
  },
  email: {
    type: String,
    regEx: SimpleSchema.RegEx.Email,
  },
  password: {
    type: String,
    min: 8,
  },
});

const useStyles = makeStyles(theme => ({
  button: {
    width: '100%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    boxShadow: 'none',
  },
}));

const SignupPage = ({ classes: eClasses, accounts, history, t }) => {
  const classes = useStyles({ classes: eClasses });
  const [state, setState] = useState({
    errors: null,
    form: {
      username: '',
      email: '',
      password: '',
    },
  });

  const { errors, form } = state;

  const handleSubmit = () => {
    const validationContext = signupSchema.newContext();

    validationContext.validate(form);
    validationContext.errorMessages = () => {
      const reducer = (memo, { name }) => ({
        ...memo,
        [name]: validationContext.keyErrorMessage(name),
      });
      return validationContext.validationErrors().reduce(reducer, {});
    };

    if (!validationContext.isValid()) {
      // client side schema check
      setState({ errors: validationContext.errorMessages() });
    } else {
      const result = accounts.register(form);
      if (result && result.error) {
        setState({ errors: { email: result.error } });
      } else {
        history.push('/');
      }
    }
  };

  const handleFieldChange = name => event => {
    const { value } = event.target;
    if (value || value.length === 0) {
      setState(({ errors: e, form: f }) => ({
        form: {
          ...f,
          [name]: value,
        },
        errors: {
          ...e,
          [name]: null,
        },
      }));
    }
  };

  return (
    <Fragment>
      <TextField
        id="username"
        name="username"
        fullWidth
        margin="normal"
        label={t('username')}
        error={errors && !!errors.username}
        helperText={errors && errors.username}
        onChange={handleFieldChange('username')}
        value={form.username}
        autoFocus
        required={false}
      />
      <TextField
        id="email"
        name="email"
        fullWidth
        margin="normal"
        label={t('email address')}
        error={errors && !!errors.email}
        helperText={errors && errors.email}
        onChange={handleFieldChange('email')}
        value={form.email}
        required={false}
      />
      <TextField
        id="password"
        name="password"
        fullWidth
        margin="normal"
        label={t('password').capitalizeFirstLetter()}
        error={errors && !!errors.password}
        helperText={errors && errors.password}
        onChange={handleFieldChange('password')}
        value={form.password}
        inputProps={{ type: 'password' }}
        required={false}
      />
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        type="submit"
      >
        {t('signUp')}
      </Button>
      <Typography variant="body2">
        {t('signupPage.loginCTAText')}{' '}
        <Link to="login">{t('signupPage.loginCTALink')}</Link>
      </Typography>
    </Fragment>
  );
};

SignupPage.propTypes = {
  accounts: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default SignupPage;
