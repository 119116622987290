import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Paper } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3),
    margin: '0 auto',
    position: 'absolute',
    transform: 'translateY(0)',
    borderRadius: 0,
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  logo: {
    display: 'block',
    width: 64,
    margin: '0 auto',
    paddingBottom: theme.spacing(3),
  },
  [theme.breakpoints.up('sm')]: {
    paper: {
      maxWidth: 380,
      position: 'relative',
      transform: 'translateY(96px)',
      borderRadius: 2,
    },
  },
}));

const AuthLayout = ({ classes: eClasses, children }) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
      }}
    >
      <Paper className={classes.paper} elevation={3}>
        <img src="/images/logo.png" className={classes.logo} alt="logo" />
        {children}
      </Paper>
    </div>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

// prettier-ignore
export default AuthLayout;
