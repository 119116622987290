import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { RadioButton, RadioButtonGroup } from '@solar/solar-react';

const useStyles = makeStyles(theme => ({
  root: {
    width: 176,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export default ({ lang, onChange }) => {
  const classes = useStyles();
  return (
    <RadioButtonGroup
      value={lang || 'en'}
      className={classes.root}
      onChange={event => onChange(event.target.value)}
    >
      <RadioButton label="EN" value="en" />
      <RadioButton label="NL" value="nl" />
    </RadioButtonGroup>
  );
};
