import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import {
  Flex,
  Rounded,
  ChatMessageGroup,
  ChatText,
  ChatImage,
  ChatDivider,
} from '@solar/solar-react';
import { Typography, Avatar } from '@material-ui/core';

import { Emojione } from 'react-emoji-render';

const useStyles = makeStyles(theme => ({
  root: {
    // ChatOutput parent should always be a Flex column -> fragments?
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  // TODO: move this shit to Solar React:
  messageGroup: {
    maxWidth: '80%',
  },
  messageGroupRight: {
    marginRight: 8,
  },
  message: {
    '&:not(:last-child)': {
      marginBottom: 2,
    },
  },
  avatar: {
    width: 32,
    height: 32,
    marginRight: 12,
    marginBottom: 16,
  },
  username: {
    paddingLeft: 12,
    marginBottom: 2,
  },
}));

const MessageGroup = ({ classes, user, right, messages, componentMap }) => {
  const map = {
    text: (message, right) => (
      <ChatText
        key={message.text}
        color={right ? 'primary' : null}
        align={right ? 'right' : 'left'}
        component={Rounded}
        className={classes.message}
      >
        <Emojione svg text={message.text} />
      </ChatText>
    ),
    imgSrc: (message, right) => (
      <ChatImage
        key={message.imgSrc}
        src={message.imgSrc}
        align={right ? 'right' : 'left'}
        component={Rounded}
        className={classes.message}
      />
    ),
    ...componentMap,
  };
  const grouped = (
    <ChatMessageGroup
      align={right ? 'right' : 'left'}
      className={classNames(
        classes.messageGroup,
        right ? classes.messageGroupRight : '',
      )}
    >
      {messages.map(message => {
        const prop = Object.keys(map).find(key => message[key]);
        if (prop) {
          return map[prop](message, right);
        }
      })}
    </ChatMessageGroup>
  );

  return right ? (
    grouped
  ) : (
    <Flex alignItems="flex-end" flexGrow={1}>
      <Avatar className={classes.avatar} src={user.avatarUrl} />
      <Flex flexDirection="column" flexGrow={1}>
        <Typography variant="caption" className={classes.username}>
          {user.username}
        </Typography>
        {grouped}
      </Flex>
    </Flex>
  );
};

const Conversation = ({
  classes: eClasses,
  className,
  messages,
  user,
  componentMap,
}) => {
  const classes = useStyles({ classes: eClasses });
  // group messages per user
  const groups = [];
  let g = null;
  let prev = null;
  messages.forEach(message => {
    if (prev !== message.user._id) {
      // new group
      if (g) {
        groups.push(g);
      }
      g = [];
      prev = message.user._id;
    }
    g.push(message);
  });
  if (g) {
    groups.push(g);
  }
  return (
    <div className={classNames(classes.root, className)}>
      {groups.map(group => (
        <React.Fragment key={group[0].text}>
          <ChatDivider>{group[0].time}</ChatDivider>
          <MessageGroup
            classes={classes}
            messages={group}
            user={group[0].user}
            right={group[0].user._id === user}
            componentMap={componentMap}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

Conversation.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  componentMap: PropTypes.object,
};

Conversation.defaultProps = {
  className: '',
};

// prettier-ignore
export default Conversation;
