import React, { useState, useEffect } from 'react';

import { pick, equals, not } from 'ramda';

export default (editableFields, editable, id) => {
  const [edits, setEdits] = useState(pick(editableFields.concat(['_id']), editable));
  useEffect(() => {
    setEdits(pick(editableFields.concat(['_id']), editable));
  }, [id]);
  const isDirty = not(equals(pick(editableFields, editable), pick(editableFields, edits)));

  console.log('dirty', pick(editableFields, editable), pick(editableFields, edits))

  return [
    isDirty,
    c => setEdits({ ...edits, ...c }),
    { ...editable, ...edits },
    () => setEdits(pick(editableFields.concat(['_id']), editable)),
  ];
};
