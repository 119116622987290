import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, IconButton, TextField } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 12,
    marginLeft: 24,
    marginRight: 24,
  },
  editField: {},
  editKey: {
    width: 64,
    marginRight: 8,
  },
}));

export const EditTuples = ({ label, tuples, onChange, ...props }) => {
  const classes = useStyles(props);
  return (
    <Fragment>
      {label}
      <div className={classes.root}>
        {Object.keys(tuples).map(t => (
          <div style={{ display: 'flex' }}>
            <TextField
              classes={{ root: classes.editKey }}
              id="outlined-dense-multiline"
              margin="dense"
              variant="outlined"
              value={t}
              onChange={event => {
                onChange(
                  Object.keys(tuples).reduce(
                    (memo, key) =>
                      t === key
                        ? { ...memo, [event.target.value]: tuples[t] }
                        : { ...memo, [key]: tuples[key] },
                    {},
                  ),
                );
              }}
            />
            <TextField
              classes={{ root: classes.editField }}
              id="outlined-dense-multiline"
              margin="dense"
              variant="outlined"
              fullWidth
              value={tuples[t]}
              onChange={event => {
                onChange({ ...tuples, [t]: event.target.value });
              }}
            />
            <IconButton
              onClick={() => {
                onChange(
                  Object.keys(tuples).reduce(
                    (memo, key) =>
                      t === key ? memo : { ...memo, [key]: tuples[key] },
                    {},
                  ),
                );
              }}
            >
              <RemoveIcon />
            </IconButton>
          </div>
        ))}
        <Button onClick={() => onChange({ ...tuples, '': '' })}>
          toevoegen
        </Button>
      </div>
    </Fragment>
  );
};

EditTuples.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  tuples: PropTypes.object.isRequired,
};

export default EditTuples;
