import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 12,
    marginLeft: 24,
    marginRight: 24,
  },
  editField: {},
  editKey: {
    width: 64,
    marginRight: 8,
  },
}));

const EditField = props => {
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      <TextField
        classes={{ root: classes.editField }}
        id="outlined-dense-multiline"
        margin="dense"
        variant="outlined"
        fullWidth
        {...props}
      />
    </div>
  );
};

EditField.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default EditField;
