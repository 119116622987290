import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Link } from 'react-router-dom';

import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  list: {
    margin: `0 -${theme.spacing(3)}px`,
  },
  listItem: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  spaceTop: {
    marginTop: theme.spacing(2),
  },
}));

const BallerLoginPage = ({ classes: eClasses, history, t, accounts }) => {
  const classes = useStyles({ classes: eClasses });

  const handleLogin = user => {
    accounts.login(user);
    history.push('/');
  };

  return (
    <Fragment>
      <List className={classes.list}>
        {accounts.allUsers.map((user, index) => (
          <ListItem
            button
            key={index}
            data-user-id={index}
            onClick={event => handleLogin(user)}
            className={classes.listItem}
          >
            <ListItemAvatar>
              <Avatar src={user.avatarUrl} />
            </ListItemAvatar>
            <ListItemText primary={user.username} />
          </ListItem>
        ))}
      </List>
      <Typography variant="body2" className={classes.spaceTop}>
        <Link to="/login">{t('ballerLogin.CTALink')}</Link>
      </Typography>
    </Fragment>
  );
};

BallerLoginPage.propTypes = {
  accounts: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

// prettier-ignore
export default BallerLoginPage;
